export default [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/index')
  },
  {
    path: '/copyrightDisplay', //版权展示
    name: 'copyrightDisplay',
    component: () => import('../views/copyrightDisplay')
  },
  {
    path: '/copyrightDisplayDetail', //版权展示详情
    name: 'copyrightDisplayDetail',
    component: () => import('../views/copyrightDisplayDetail')
  },
  {
    path: '/copyrightWorksDisplay', //版权展示
    name: 'copyrightWorksDisplay',
    component: () => import('../views/copyrightWorksDisplay')
  },
  {
    path: '/copyrightWorksDisplayDetail', //版权展示详情
    name: 'copyrightWorksDisplayDetail',
    component: () => import('../views/copyrightWorksDisplayDetail')
  },
  {
    path: '/copyrightRegistration', //版权发布
    name: 'copyrightRegistration',
    component: () => import('../views/copyrightRegistration')
  },
  {
    path: '/copyrightWorkRegistration', //版权作品发布
    name: 'copyrightWorkRegistration',
    component: () => import('../views/copyrightWorkRegistration')
  },
  {
    path: '/information', //资讯公告
    name: 'information',
    component: () => import('../views/information')
  },
  {
    path: '/informationDetail', //资讯公告详情
    name: 'informationDetail',
    component: () => import('../views/informationDetail')
  },
  {
    path: '/onlineLearning', //在线学习
    name: 'onlineLearning',
    component: () => import('../views/onlineLearning')
  },
  {
    path: '/onlineLearningDetail', //在线学习详情
    name: 'onlineLearningDetail',
    component: () => import('../views/onlineLearningDetail')
  },
  {
    path: '/personalCenter', //个人中心
    name: 'personalCenter',
    component: () => import('../views/personalCenter')
  },
  {
    path: '/myFavorites', //我的收藏
    name: 'myFavorites',
    component: () => import('../views/myFavorites')
  },
  {
    path: '/demandHall', //需求大厅
    name: 'demandHall',
    component: () => import('../views/demandHall')
  },
  {
    path: '/demandDetail', //需求详情
    name: 'demandDetail',
    component: () => import('../views/demandDetail')
  },
  {
    path: '/releaseDemand', //发布需求
    name: 'releaseDemand',
    component: () => import('../views/releaseDemand')
  },
  {
    path: '/legalAdvice', //法律咨询
    name: 'legalAdvice',
    component: () => import('../views/legalAdvice')
  },
  {
    path: '/legalAdviceSign', // 律所入驻
    name: 'legalAdviceSign',
    component: () => import('../views/legalAdviceSign')
  },
  {
    path: '/legalAdviceQuestions', //法律咨询-提问
    name: 'legalAdviceQuestions',
    component: () => import('../views/legalAdviceQuestions')
  },
  {
    path: '/financialService', //金融服务
    name: 'financialService',
    component: () => import('../views/financialService')
  },
  {
    path: '/financialServiceSign', //金融服务 - 申请入驻
    name: 'financialServiceSign',
    component: () => import('../views/financialServiceSign')
  },
  {
    path: '/financialServiceApplication', //金融服务-申请
    name: 'financialServiceApplication',
    component: () => import('../views/financialServiceApplication')
  },
  {
    path: '/applyingForResidency', //申请入驻
    name: 'applyingForResidency',
    component: () => import('../views/applyingForResidency')
  },
  {
    path: '/companyRegi', //申请入驻
    name: 'companyRegi',
    component: () => import('../views/companyRegi')
  }
];
