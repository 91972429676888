import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Vant, { Lazyload } from 'vant';
import 'vant/lib/index.css';
import { Swipe, SwipeItem } from 'vant';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import '../rem.js';
Vue.use(ElementUI);

import scroll from 'vue-seamless-scroll';
Vue.use(scroll);

// import vueAwesomeSwiper from 'vue-awesome-swiper';
// import 'swiper/css/swiper.css';

// Vue.use(vueAwesomeSwiper);

Vue.use(Vant);
Vue.use(Lazyload);
Vue.use(Swipe);
Vue.use(SwipeItem);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
