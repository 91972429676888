import Router from 'vue-router';
import Vue from 'vue';
import system from './system';
import { Toast } from 'vant';
import elementUi from 'element-ui';
Vue.use(Router);

const router = new Router({
  base: process.env.NODE_ENV === 'production' ? '/' : '/',
  routes: system
});

const loadingToast = Toast.loading({
  overlay: true,
  message: '加载中...'
});

router.beforeEach((to, from, next) => {
  loadingToast.open();
  console.log(from.path + ' route to ' + to.path);
  // if (to.path === from.path) {
  //   next(false);
  // }
  if (to.meta.title) {
    //判断是否有标题
    console.log(to.meta.title);
    document.title = to.meta.title;
  } else {
    document.title = '版权交易服务平台';
  }
  const unLoginSee = [
    'index',
    'onlineLearning',
    'copyrightDisplay',
    'copyrightDisplayDetail',
    'information',
    'companyRegi',
    'copyrightWorksDisplay',
    'demandHall',
    'demandDetail',
    'copyrightWorksDisplayDetail',
    'onlineLearningDetail',
    'informationDetail',
  ]
  console.log(to.name);
  if (!unLoginSee.includes(to.name)) {
    if (!localStorage.getItem('bqsb_login')) {
      elementUi.Message.success('请先登录');
      return;
    }
    if (localStorage.getItem('userType') == '2' && localStorage.getItem('audit') != '1') {
      elementUi.Message.success('请等待公司审核通过');
      return;
    }
  }
  // chrome
  document.body.scrollTop = 0;
  // firefox
  document.documentElement.scrollTop = 0;
  // safari
  window.pageYOffset = 0;
  next();
});

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  loadingToast.clear();
});
export default router;
